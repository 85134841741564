.login-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url('../constants/images/login_Sahay.png') no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(119, 91, 229, 0.7), rgba(119, 91, 229, 0.7));
  z-index: 1;
}

.form-container {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.login-text {
  color: black;
  font-size: 24px;
}

.logo {
  height: 50px;
}

.input-label {
  margin-bottom: 4px;
  font-size: 14px;
  color: #555;
}

.input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.password-container {
  position: relative;
  margin-bottom: 4px; /* Reduce margin here */
}

.toggle-password-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.button-confirm {
  width: 100%;
  padding: 10px;
  background-color: #775BE5;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.forgot-password {
  cursor: pointer;
  margin-bottom: 20px; /* Adjust margin here if needed */
}

@media (min-width: 768px) {
  .form-container {
    width: 80%;
  }
}

@media (min-width: 1024px) {
  .form-container {
    width: 60%;
  }
}
